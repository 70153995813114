<template>
  <div class="header">
    <div class="header__content container">
      <div class="header__items">
        <button @click="openModal" class="header__items-right-burger d-none">
          <svg width="29" height="17" viewBox="0 0 29 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="29" height="3" rx="1.5" fill="#2EA147" />
            <rect y="7" width="29" height="3" rx="1.5" fill="#2EA147" />
            <rect y="14" width="29" height="3" rx="1.5" fill="#2EA147" />
          </svg>
        </button>
        <router-link :to="{ name: 'home' }" class="header__items-left">
          <img src="@/assets/images/svg/MainLogo.svg" alt="" />
        </router-link>
        <div class="header__items-right m_none">
          <div class="header__items-right-nav m-none">
            <ul>
              <li v-for="(item, idx) in nav" :key="'nav' + idx">
                <router-link class="f-16 btn-link-green" :to="{ name: item.link }">{{
                  item.title
                }}</router-link>
              </li>
            </ul>
          </div>
          <div class="header__items-right-pc ">
            <button @click="toCreate()" class="btn-green f-14">
              <span><svg width="24" height="24" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5 6.25V13.75" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M6.75 10H14.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M1.125 10C1.125 12.4864 2.11272 14.871 3.87087 16.6291C5.62903 18.3873 8.0136 19.375 10.5 19.375C12.9864 19.375 15.371 18.3873 17.1291 16.6291C18.8873 14.871 19.875 12.4864 19.875 10C19.875 7.5136 18.8873 5.12903 17.1291 3.37087C15.371 1.61272 12.9864 0.625 10.5 0.625C8.0136 0.625 5.62903 1.61272 3.87087 3.37087C2.11272 5.12903 1.125 7.5136 1.125 10V10Z"
                    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
              <p>{{ $t("button.createAds") }}</p>
            </button>

            <div v-if="isAuth" class="header__items-right-dropdown f-14 btn-white">
              <div class="header__items-right-dropdown-links" @click="showInfo(open[0], 0)"
                :class="{ 'title--active': open[0] }">
                <div class="header__items-right-dropdown-links-mainTitle"><span><svg width="24" height="24"
                      viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M5.5998 20.8V19.9998C5.5998 17.3489 7.74884 15.2 10.3998 15.2H13.5998C16.2508 15.2 18.3998 17.3489 18.3998 19.9998V20.8M11.9998 5.60005C10.2325 5.60005 8.7998 7.03274 8.7998 8.80005C8.7998 10.5674 10.2325 12 11.9998 12C13.7671 12 15.1998 10.5674 15.1998 8.80005C15.1998 7.03274 13.7671 5.60005 11.9998 5.60005ZM11.9998 23.2C5.81422 23.2 0.799805 18.1856 0.799805 12C0.799805 5.81446 5.81422 0.800049 11.9998 0.800049C18.1854 0.800049 23.1998 5.81446 23.1998 12C23.1998 18.1856 18.1854 23.2 11.9998 23.2Z"
                        stroke="#2EA147" stroke-linecap="square" />
                    </svg>
                  </span>
                  <p>{{ userData.full_name ? userData.full_name : $t('button.personalCabinet')}}</p>
                </div>
                <span v-if="!open[0]"><svg width="12" height="8" viewBox="0 0 12 8" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.0002 1.17019C10.8128 0.983936 10.5594 0.879395 10.2952 0.879395C10.031 0.879395 9.77756 0.983936 9.59019 1.17019L6.00019 4.71019L2.46019 1.17019C2.27283 0.983936 2.01938 0.879395 1.75519 0.879395C1.49101 0.879395 1.23756 0.983936 1.05019 1.17019C0.956464 1.26315 0.88207 1.37375 0.831301 1.49561C0.780533 1.61747 0.754395 1.74818 0.754395 1.88019C0.754395 2.0122 0.780533 2.1429 0.831301 2.26476C0.88207 2.38662 0.956464 2.49722 1.05019 2.59019L5.29019 6.83019C5.38316 6.92392 5.49376 6.99831 5.61562 7.04908C5.73747 7.09985 5.86818 7.12599 6.00019 7.12599C6.1322 7.12599 6.26291 7.09985 6.38477 7.04908C6.50663 6.99831 6.61723 6.92392 6.71019 6.83019L11.0002 2.59019C11.0939 2.49722 11.1683 2.38662 11.2191 2.26476C11.2699 2.1429 11.296 2.0122 11.296 1.88019C11.296 1.74818 11.2699 1.61747 11.2191 1.49561C11.1683 1.37375 11.0939 1.26315 11.0002 1.17019Z"
                      fill="#2EA147" />
                  </svg>
                </span>
                <span v-else><svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.295706 5.95579C0.483068 6.14204 0.73652 6.24658 1.00071 6.24658C1.26489 6.24658 1.51834 6.14204 1.70571 5.95579L5.29571 2.41579L8.83571 5.95579C9.02307 6.14204 9.27652 6.24658 9.54071 6.24658C9.80489 6.24658 10.0583 6.14204 10.2457 5.95579C10.3394 5.86283 10.4138 5.75223 10.4646 5.63037C10.5154 5.50851 10.5415 5.3778 10.5415 5.24579C10.5415 5.11378 10.5154 4.98307 10.4646 4.86121C10.4138 4.73935 10.3394 4.62875 10.2457 4.53579L6.00571 0.29579C5.91274 0.202061 5.80214 0.127667 5.68028 0.0768986C5.55842 0.0261297 5.42772 -8.58307e-06 5.29571 -8.58307e-06C5.16369 -8.58307e-06 5.03299 0.0261297 4.91113 0.0768986C4.78927 0.127667 4.67867 0.202061 4.58571 0.29579L0.295706 4.53579C0.201978 4.62875 0.127584 4.73935 0.0768147 4.86121C0.0260458 4.98307 -9.34601e-05 5.11378 -9.34601e-05 5.24579C-9.34601e-05 5.3778 0.0260458 5.50851 0.0768147 5.63037C0.127584 5.75223 0.201978 5.86283 0.295706 5.95579Z"
                      fill="#2EA147" />
                  </svg>
                </span>
              </div>
              <dropdownVue class="header__items-right-dropdown-list" :open="open[0]"
                :class="{ databody__active: open[0] }">
                <div class="header__items-right-dropdown-list-all f-14" v-for="(link, idx) in personalLinks" :key="idx">
                  <button @click.prevent="toPage(link)">
                    <img :src="link.icon" alt="">
                    <p>{{ link.text }}</p>
                  </button>
                </div>
              </dropdownVue>
            </div>
            <router-link v-else :to="{ name: 'Login' }" class="f-14 btn-white">
              <span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.5998 20.8V19.9998C5.5998 17.3489 7.74884 15.2 10.3998 15.2H13.5998C16.2508 15.2 18.3998 17.3489 18.3998 19.9998V20.8M11.9998 5.60005C10.2325 5.60005 8.7998 7.03274 8.7998 8.80005C8.7998 10.5674 10.2325 12 11.9998 12C13.7671 12 15.1998 10.5674 15.1998 8.80005C15.1998 7.03274 13.7671 5.60005 11.9998 5.60005ZM11.9998 23.2C5.81422 23.2 0.799805 18.1856 0.799805 12C0.799805 5.81446 5.81422 0.800049 11.9998 0.800049C18.1854 0.800049 23.1998 5.81446 23.1998 12C23.1998 18.1856 18.1854 23.2 11.9998 23.2Z"
                    stroke="#2EA147" stroke-linecap="square" />
                </svg>
              </span>
              <p>{{ $t("button.personalCabinet") }}</p>
            </router-link>
          </div>
          <div class="header__items-right-lang">
            <button class="f-14 btn-link-green" v-for="(link, idx) in langLinks" @click.prevent="changeLang(link)"
              :key="idx">{{ link.name }}</button>
          </div>
        </div>

        <div class="header__items-rightMobile d-none">
          <router-link :to="{ name: 'Login' }" v-if="!isAuth" class="btn-green f-14">Войти</router-link>
          <router-link :to="{ name: 'MyProfile' }" v-else class="btn-green f-14"><span><svg width="24" height="24"
                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.5998 20.8V19.9998C5.5998 17.3489 7.74884 15.2 10.3998 15.2H13.5998C16.2508 15.2 18.3998 17.3489 18.3998 19.9998V20.8M11.9998 5.60005C10.2325 5.60005 8.7998 7.03274 8.7998 8.80005C8.7998 10.5674 10.2325 12 11.9998 12C13.7671 12 15.1998 10.5674 15.1998 8.80005C15.1998 7.03274 13.7671 5.60005 11.9998 5.60005ZM11.9998 23.2C5.81422 23.2 0.799805 18.1856 0.799805 12C0.799805 5.81446 5.81422 0.800049 11.9998 0.800049C18.1854 0.800049 23.1998 5.81446 23.1998 12C23.1998 18.1856 18.1854 23.2 11.9998 23.2Z"
                  stroke="#FFFFFF" stroke-linecap="square" />
              </svg>
            </span></router-link>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dropdownVue from "@/components/dropdown";
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  components: {
    dropdownVue,
  },
  data() {
    return {
      nav: [
        {
          title: this.$t('links.ads'),
          link: "Catalog",
        },
        {
          title: this.$t('links.prodvizh'),
          link: "Promotion",
        },
        {
          title: this.$t('links.organization'),
          link: "Organization",
        },
      ],
      personalLinks: [
        {
          text: this.$t('button.profile'),
          icon: require('@/assets/images/svg/RouteLogo.svg'),
          route: 'MyProfile'
        },
        {
          text: this.$t('button.ads'),
          icon: require('@/assets/images/svg/RouteLogo2.svg'),
          route: 'MyAds'
        }, {
          text: this.$t('button.messages'),
          icon: require('@/assets/images/svg/RouteLogo3.svg'),
          route: 'MyMessages'
        }, {
          text: this.$t('button.balance'),
          icon: require('@/assets/images/svg/RouteLogo4.svg'),
          route: 'MyBalance'
        }, {
          text: this.$t('button.isFavourite'),
          icon: require('@/assets/images/svg/RouteLogo5.svg'),
          route: 'MyFavourites'
        },
        // {
        //   text: this.$t('button.settings'),
        //   icon: require('@/assets/images/svg/RouteLogo6.svg'),
        //   route: ''
        // }, 
        {
          text: this.$t('links.organization'),
          icon: require('@/assets/images/svg/RouteLogo.svg'),
          route: 'MyOrganization'
        }, {
          text: this.$t('button.logout'),
          icon: require('@/assets/images/svg/RouteLogo.svg'),
          route: 'logout'
        }
      ],
      open: [false],
      selected: localStorage.getItem("lang") || "ru",
      langLinks: [
        {
          name: "KZ",
          value: "kz",
        },
        {
          name: "RU",
          value: "ru",
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ isAuth: 'GET_IS_AUTH', userData: 'GET_USER' })
  },
  created() {
    this.CHECK_AUTH()
  },
  methods: {
    ...mapActions(['CHECK_AUTH', 'LOGOUT']),
    ...mapMutations(['SET_MODAL']),
    openModal() {
      this.SET_MODAL()
    },
    showInfo(value, index) {
      value = !value;
      this.open[index] = value;
      this.$nextTick(this.$forceUpdate);
    },
    toPage(value) {
      if (value.route === 'logout') {
        this.open[0] = false
        this.LOGOUT();
      } else {
        this.open[0] = false
        this.$router.push({ name: value.route })
      }
    },
    toCreate() {
      this.$router.push({ name: 'PublishAnnouncement' })
      // if(this.$store.state.isAuth){
      //   this.$router.push({ name: 'PublishAnnouncement' })
      // } else {
      //   this.$router.push({name: 'Login'})
      // }
    },
    changeLang(value) {
      localStorage.setItem("lang", value.value);
      window.location.reload();
    },
  },
};
</script>

<style></style>
