<template>
  <Header />
  <component :is="componentId">
    <router-view />
  </component>
  <Footer />
  <modal :close-show="true" :open="opened" @close="openModal">
    <modalNavbar />
  </modal>
  <div v-if="$route.name !== 'PublishAnnouncement'" class="bottomNav d-none">
    <div class="bottomNav__items">
      <button
            
            @click="toCreate()"
            class="btn-green f-14"
          >
            <span
              ><svg
                width="24"
                height="24"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 6.25V13.75"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.75 10H14.25"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.125 10C1.125 12.4864 2.11272 14.871 3.87087 16.6291C5.62903 18.3873 8.0136 19.375 10.5 19.375C12.9864 19.375 15.371 18.3873 17.1291 16.6291C18.8873 14.871 19.875 12.4864 19.875 10C19.875 7.5136 18.8873 5.12903 17.1291 3.37087C15.371 1.61272 12.9864 0.625 10.5 0.625C8.0136 0.625 5.62903 1.61272 3.87087 3.37087C2.11272 5.12903 1.125 7.5136 1.125 10V10Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <p>Добавить объявление</p>
          </button>
    </div>
  </div>
</template>

<script>
import Header from "@/components/layouts/Header.vue";
import Footer from "./components/layouts/Footer.vue";
import PersonalLayout from "./layout/PersonalLayout.vue";
import modal from "@/components/modal/modalv4.vue"
import { mapGetters, mapMutations } from 'vuex';
import modalNavbar from '@/components/modal/modalNavbar.vue';

export default {
  name: "App",
  components: {
    Header,
    Footer,
    PersonalLayout,
    modal,
    modalNavbar
  },
  data: () => ({}),
  computed: {
    ...mapGetters({ opened: 'GET_OPENED' }),
    componentId() {
      return (this.$route.meta.layout || "base") + "-layout";
    },
  },
  methods: {
    ...mapMutations(['SET_MODAL']),
    openModal() {
      this.SET_MODAL()
    },
    toCreate(){
      this.$router.push({ name: 'PublishAnnouncement' })

    }
  }
};
</script>
<style scoped lang="scss">
.bottomNav{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 997!important;
  // background-color: #FFFFFF;
  // box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);


  &__items{
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
}
</style>
