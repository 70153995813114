<template>
    <div class="modals">
        <div class="modals__items">
            <div class="modals__items-list">
                <ul>
                    <li v-for="(item, idx) in navLink" :key="idx">
                        <a @click="toPage(item)" class="f-20" to="#">{{ item.name }}</a>
                    </li>
                </ul>
            </div>
            <div class="modals__items-bottom">
                <div class="modals__items-bottom-lang">
                    <button class="f-20 btn-link-green" v-for="(link, idx) in langLinks" @click.prevent="changeLang(link)"
                        :key="idx">{{ link.name }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
    data() {
        return {
            navLink: [
                {
                    name: this.$t('links.ads'),
                    route: 'Catalog',
                    params: ''
                },
                {
                    name:  this.$t('links.prodvizh'),
                    route: 'Promotion',
                    params: ''
                },
                {
                    name: this.$t('links.organization'),
                    route: 'Organization',
                    params: ''
                },
                {
                    name: this.$t('links.news'),
                    route: 'News',
                    params: ''
                },
                {
                    name: this.$t('links.uslugi'),
                    route: 'CategoryBySlug',
                    params: 'uslugi'
                },
                {
                    name: this.$t('links.forums'),
                    route: 'Forums',
                    params: ''
                },
                {
                    name: this.$t('button.createAds'),
                    route: 'PublishAnnouncement',
                    params: ''
                },
            ],
            selected: localStorage.getItem("lang") || "ru",
            langLinks: [
                {
                    name: "KZ",
                    value: "kz",
                },
                {
                    name: "RU",
                    value: "ru",
                },
            ],
        }
    },
    methods: {
        ...mapMutations(['SET_MODAL']),
        toPage(value) {
            if (value.route == 'CategoryBySlug') {
                this.$router.push({ name: value.route, params: { slug: value.params } })
                this.SET_MODAL();
            } else {
                this.$router.push({ name: value.route })
                this.SET_MODAL();
            }
        },
        changeLang(value) {
            localStorage.setItem("lang", value.value);
            window.location.reload();
        },
    }

}
</script>

<style scoped lang="scss">
.modals {
    margin-top: 100px;

    &__items {
        &-list {
            ul {
                display: flex;
                flex-direction: column;
                gap: 10px;

                li {
                    a {
                        color: #1C682C;
                    }
                }
            }
        }

        &-bottom {
            position: fixed;
            bottom: 20px;
            left: 0;
            right: 0;
            text-align: center;

            &-lang {
                .f-20 {
                    font-weight: 400;
                    line-height: 16px;
                    text-transform: uppercase;
                    color: #2EA147;
                    padding-left: 5px;
                    padding-right: 5px;
                    border-right: 2px solid #E5E5E5;

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                        border-right: unset;
                    }
                }
            }
        }
    }

}
</style>