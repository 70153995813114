<template>
  <div class="product">
    <div :class="list === true ? 'list' : ''" class="product__content">
      <div v-if="product?.slug" @click="$router.push({name: 'ProductBySlug', params:{slug : product?.slug}})" class="product__items">
        <div class="product__items-img">
          <img v-if="product.photo" :src="product.photo" alt="" />
          <img v-else src="@/assets/images/png/noImg.png" alt=""/>
          <div v-if="isAuth" class="product__items-likeBtn">
            <button :class="product?.is_favourite === 1 ? 'acitveFav' : ''" @click.stop="addToFavourite(product)">
              <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.04721 9.04738L9.99984 17L17.9525 9.04738C18.8365 8.16332 19.3332 6.96429 19.3332 5.71405C19.3332 3.11055 17.2226 1 14.6191 1C13.3689 1 12.1698 1.49666 11.2858 2.38071L9.99984 3.66667L8.71388 2.38071C7.82983 1.49666 6.63079 1 5.38055 1C2.77705 1 0.666504 3.11055 0.666504 5.71405C0.666504 6.96429 1.16316 8.16332 2.04721 9.04738Z"
                  stroke="#2EA147"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="product__items-desc">
          <div class="product__items-desc-firstBlock">
            <div class="product__items-desc-tags t">
              <!-- <span class="t_outline-yellow f-14">ТОП</span
              > -->
              <div class="t__boosts" v-for="(item,idx) in product?.boosts" :key="idx"><img :src="item.image" /></div>
              <span class="t_gray f-14">{{product?.category ? product.category : 'Лошади'}}</span
              >
              <!-- <span class="t_green f-14">Продам</span> -->
            </div>
            <div class="product__items-desc-title">
              <span class="f-16">{{ product?.title ? product.title : 'Нет описания'}}</span>
            </div>
            <div v-if="list === true" class="product__items-desc-text">
              <span class="f-14">{{ product?.description ? product?.description : '4 годовалый жеребец.'}}</span>
            </div>
            <div class="product__items-desc-city">
              <span class="f-14">{{ product?.address ? product.address : 'Алматы'}}</span>
              <!-- <span class="f-14">{{format_date(product?.created_at)}}</span> -->
              <!-- <span class="f-14 view"
                ><svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.75 9C0.75 9 3.75 3 9 3C14.25 3 17.25 9 17.25 9C17.25 9 14.25 15 9 15C3.75 15 0.75 9 0.75 9Z"
                    stroke="#777777"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z"
                    stroke="#777777"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p>{{ product?.views ? product.views :  '0'}}</p>
              </span> -->
            </div>
          </div>
          <div class="product__items-desc-price">
            <span class="f-15">{{product?.price ? (formatPrice(product.price)) : '0'}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
  props: ["listStye",'product',"typeO"],
  data: () => ({}),
  computed: {
    list() {
      if (this.listStye) {
        return this.listStye;
      } else {
        return false;
      }
    },
    ...mapGetters({isAuth: 'GET_IS_AUTH'})
  },
  methods: {
    formatPrice: (price) => `${(+price).toLocaleString()} ₸`,
    format_date(value){
         if (value) {
           return moment(String(value)).format('DD.MM.YYYY')
          }
      },
      addToFavourite(value){
        console.log('value.id',value.id);
        let data = new FormData();
      data.append("product_id", value.id);
      axios
        .post("user-favorites/product-add", data,{
              headers: {
                Authorization: `Bearer ${this.$cookies.get("token")}`
            },
          })
        .then((response) => {
          if (response.status === 200) {
            console.log('rrr',response);
            this.$emit('update')
            // this.userSms = response.data.data;
          }
        })
        .catch((e) => {
          // this.toast.error("что то пошло не такеееее");
          console.log(e);
        });
      }
  },
};
</script>

<style></style>
