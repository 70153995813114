<template>
  <div class="findOf">
    <div class="findOf__items">
      <div class="findOf__items-header">
        <span class="f-22"> {{ $t("homepage.findOffice") }}</span>
      </div>
      <div class="findOf__items-content">
        <Search />
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/components/home/Search";
export default {
  components: { Search },
};
</script>

<style scoped lang="scss">
.findOf {
    margin-top: 100px;
  &__items {
    display: grid;
    gap: 30px;
    &-header {
      .f-22 {
        font-weight: 500;
        line-height: 26px;
        color: #000000;
      }
    }
  }
}
</style>
