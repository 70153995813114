<template>
  <div class="select">
    <div class="select__btn" @click="isVisible = !isVisible" ref="selectBtn">
      <div class="select__text uppercase">
        <div class="select__text-main">
          {{ selected.title }}
        </div>
        <font-awesome-icon
          :icon="
            isVisible ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'
          "
        />
      </div>
    </div>
    <transition name="fade">
      <ul class="select__list uppercase" v-if="isVisible">
        <li
          v-for="option in options"
          :key="option.value"
          @click="selectOption(option)"
        >
          {{ option.title }}
        </li>
      </ul>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    selected: {
      type: Object,
    },
  },
  data() {
    return {
      isVisible: false,
      cdn: null,
    };
  },
  created() {
    // this.lang.includes('ru');
  },
  mounted() {
    this.cdn = this.$store.state.cdn;
  },
  methods: {
    selectOption(option) {
      this.$emit("select", option);
      this.isVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
img {
  vertical-align: middle !important;
}

.select {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #000000;
  position: relative;
  z-index: 12;

  &__btn {
    display: flex;
    align-items: center;
  }

  &__text {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    // border: 1px solid #cfcfcf;
    min-width: 226px;
    justify-content: space-between;
    @media (max-width: 992.9px) {
      min-width: unset;
      gap: 5px;
    }
    &-main {
      display: flex;
      align-items: center;
      gap: 5px;
      text-align: left;
      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
      }
    }

    span {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }

  &__arrow {
    width: 30px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  &__list {
    position: absolute;
    border: 1px solid #cfcfcf;
    cursor: pointer;
    background-color: #ffffff;
    min-width: 226px;
    padding: 0 12px;
    margin: 0;
    max-height: 500px;
    overflow-x: auto;

    li {
      display: flex;
      align-items: center;
      padding: 7px 0;
      justify-content: flex-start;
      gap: 5px;
      text-align: left;
    }

    span {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
