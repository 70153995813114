import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
// import "swiper/css";

// import 'swiper/scss/navigation';
// import "swiper/css/free-mode";
// import "swiper/css/pagination";
// import "swiper/css/grid";
import "swiper/css";
import 'swiper/swiper.min.css';
import 'swiper/scss/navigation';
import "swiper/css/free-mode";
import "swiper/css/pagination";
import axios from 'axios';
import i18n from '@/plugins/i18n';
import VueTheMask from 'vue-the-mask'
import VueCookies from 'vue-cookies'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronDown, faXmark,faChevronUp, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import "vue3-treeselect/dist/vue3-treeselect.css";
import vClickOutside from "click-outside-vue3"
if (!localStorage.getItem("lang")) {
  localStorage.setItem("lang","ru")
}
const lang = localStorage.getItem('lang') || 'ru';
axios.defaults.baseURL = process.env.APP_URL;
axios.defaults.params = {
    'lang': lang,
  };
axios.defaults.headers = {
  Authorization: `Bearer ${$cookies.get("token")}`
}

const options = {
  position: "top-center",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
};

library.add(faXmark,faChevronUp,faChevronDown,faChevronLeft)
createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(VueTheMask)
    .use(VueCookies, { expires: '1d'})
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(Toast, options)
    .use(vClickOutside)
    .mount('#app')
