<template>
  <div class="home">
    <div class="home__content container">
      <Search class="home__content-search" />
      <Catalogs :homeData="homeData" />
      <AboutMalmarket />
      <Premium @update="update" :homeData="homeData" />
      <Fastest @update="update" :homeData="homeData" />
      <Newest @update="update" :homeData="homeData" />
      <FindOffice />
    </div>
  </div>
</template>

<script>
import Search from '@/components/home/Search'
import Catalogs from '@/components/home/Catalogs'
import Premium from '@/components/home/Premium'
import Newest from '@/components/home/Newest'
import Fastest from '@/components/home/Fastest'
import FindOffice from '@/components/home/FindOffice'
import AboutMalmarket from '@/components/home/AboutMalmarket.vue'
import axios from 'axios'
export default {
  components: {
    Search,
    Catalogs,
    Premium,
    Newest,
    FindOffice,
    AboutMalmarket,
    Fastest
  },
  data :() => ({
    homeData: null,
  }),
  created() {
    this.FETCH_HOME();
  },
  mounted(){

  },
  methods: {
    update(){
      this.FETCH_HOME()
    },
    FETCH_HOME(){
      axios
        .get("home-page",{
          headers: {
                Authorization: `Bearer ${this.$cookies.get("token")}`
            },
        })
        .then((response) => {this.homeData = response.data})
        .catch((e) => {
          console.log(e);
          
        });
    
    }
  }
}
</script>
