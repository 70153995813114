<template>
    <div v-if="homeData" class="homeCatalog">
        <div class="homeCatalog__content">
            <div class="homeCatalog__items row">
                <div class="homeCatalog__items-left col">
                    <ul>
                        <li v-for="(item,idx) in homeData.homePageCategories" :key="idx">
                            <img v-if="item.logo" :src="item.logo" alt="">
                            <router-link class="f-18" :to="{name: 'CategoryBySlug',params: {Categoryslug: item.slug}}">
                            <span>{{ item.label }}
                                <p>({{ item.products_count ? item.products_count : 0 }}-объяв.)</p>
                            </span>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="homeCatalog__items-right col">
                    <img src="@/assets/images/svg/Kazakhstan.svg" alt="">
                    <button class="f-18">{{ $t("homepage.allPublish") }}</button>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['homeData'],
    data: () => ({
    })

}
</script>

<style>

</style>