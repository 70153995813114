<template>
  <div v-if="homeData" class="premium">
    <div class="premium__items">
      <div class="premium__items-top">
        <div class="premium__items-top-left">
          <span class="f-22"> {{ $t("homepage.premium") }}</span>
          
        </div>
        <div class="premium__items-top-right">
            <div class="premium__items-top__navigation">
            <div class="swiper-button-next selectornx2"></div>
            <div class="swiper-button-prev selectorpr2"></div>
          </div>
        </div>
      </div>    
      <div class="premium__items-bottom">
        <!-- :grid="{ rows: 2 }" -->
        <swiper
          
          :slidesPerView="5"
          :spaceBetween="5"
          :breakpoints="breakpoints"
          :modules="modules"
          :navigation="navigation"
          class="premium__items-bottom-swiper"
        >
          <swiper-slide
            class="premium__items-bottom-swiper-swiper-slide"
            v-for="item in homeData.premiumPosts"
            :key="item"
            
          >
            <Product @update="update" :product="item" />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import Product from "../Product";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Grid, Navigation } from "swiper";
import "swiper/css/grid";
SwiperCore.use([Navigation, Grid]);
export default {
  props: ['homeData'],
  components: { Product, Swiper, SwiperSlide },
  data: () => ({
    navigation: {
    nextEl: ".selectornx2",
    prevEl: ".selectorpr2",
  },
  breakpoints: {
    // when window width is >= 320px
    0: {
      slidesPerView: 1.1,
      // spaceBetween: 10,
    },
    // when window width is >= 480px
    655: {
      slidesPerView: 1.2,
      // spaceBetween: 10,
    },
    // when window width is >= 640px
    1000: {
      slidesPerView: 1.5,
      // spaceBetween: 33,
    },
    1300: {
      slidesPerView: 5,
      spaceBetween: 10,
    },
  },
  }),
  methods:{
    update(){
     
      this.$emit('update')
    }
  }
};
</script>

<style lang="scss">
.swiper-wrapper {
  flex-direction: unset !important;
  gap: 10px;
}
.swiper-slide.premium__items-bottom-swiper-swiper-slide.swiper-slide-next {
  margin-top: 0 !important;
  margin-right: 0 !important;
}

.swiper-slide.premium__items-bottom-swiper-swiper-slide {
  margin-top: 0 !important;
  margin-right: 0 !important;
  // margin-bottom: 5px!important;
}
</style>
