import { faListSquares } from '@fortawesome/free-solid-svg-icons'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/category/:Categoryslug',
    name: 'CategoryBySlug',
    meta: {
      requiresAuth: false,
    },
    component: function () {
      return import('../views/CategoryView.vue')
    }
  },
  {
    path: '/product/:slug',
    name: 'ProductBySlug',
    meta: {
      requiresAuth: false,
    },
    component: function () {
      return import('../views/ProductView.vue')
    }
  },
  {
    path: '/auth/login',
    name: 'Login',
    meta: {
      requiresAuth: false,
    },
    component: function () {
      return import('../views/auth/Login.vue')
    }
  },
  {
    path: '/auth/signup',
    name: 'Registration',
    meta: {
      requiresAuth: false,
    },
    component: function () {
      return import('../views/auth/Register.vue')
    }
  },
  {
    path: '/auth/forget',
    name: 'Forget',
    meta: {
      requiresAuth: false,
    },
    component: function () {
      return import('../views/auth/Forget.vue')
    }
  },
  {
    path: '/publish-announcement',
    name: 'PublishAnnouncement',
    meta: {
      requiresAuth: false,
    },
    component: function () {
      return import('../views/PublishAnnouncement.vue')
    }
  },
  {
    path: '/user/my-profile',
    name: 'MyProfile',
    meta: {
      layout: "Personal",
      requiresAuth: true,
    },
    component: function () {
      return import('../views/user/MyProfile.vue')
    }
  },
  {
    path: '/user/my-ads',
    name: 'MyAds',
    meta: {
      layout: "Personal",
      requiresAuth: true,
    },
    component: function () {
      return import('../views/user/MyAds.vue')
    }
  },
  {
    path: '/user/my-balance',
    name: 'MyBalance',
    meta: {
      layout: "Personal",
      requiresAuth: true,
    },
    component: function () {
      return import('../views/user/MyBalance.vue')
    }
  },
  {
    path: '/user/my-favourites',
    name: 'MyFavourites',
    meta: {
      layout: "Personal",
      requiresAuth: true,
    },
    component: function () {
      return import('../views/user/MyFavourites.vue')
    }
  },
  {
    path: '/user/my-organization',
    name: 'MyOrganization',
    meta: {
      layout: "Personal",
      requiresAuth: true,
    },
    component: function () {
      return import('../views/user/MyOrganization.vue')
    }
  },
  {
    path: '/user/my-messages',
    name: 'MyMessages',
    meta: {
      layout: "Personal",
      requiresAuth: true,
    },
    component: function () {
      return import('../views/user/MyMessages.vue')
    }
  },
  {
    path: '/promotion',
    name: 'Promotion',
    meta: {
      requiresAuth: false,
    },
    component: function () {
      return import('../views/PromotionView.vue')
    }
  },
  {
    path: '/forums',
    name: 'Forums',
    meta: {
      requiresAuth: false,
    },
    component: function () {
      return import('../views/ForumView.vue')
    }
  },
  {
    path: '/forums/:id',
    name: 'ForumsById',
    meta: {
      requiresAuth: false,
    },
    component: function () {
      return import('../views/ThemeByForum.vue')
    }
  },
  {
    path: '/forums/:id/:secondid',
    name: 'ThemesById',
    meta: {
      requiresAuth: false,
    },
    component: function () {
      return import('../views/MessageForum.vue')
    }
  },
  {
    path: '/news',
    name: 'News',
    meta: {
      requiresAuth: false,
    },
    component: function () {
      return import('../views/NewsView.vue')
    }
  },
  {
    path: '/news/:slug',
    name: 'newsBySlug',
    meta: {
      requiresAuth: false,
    },
    component: function () {
      return import('../views/NewsBySlug.vue')
    }
  },
  {
    path: '/organization',
    name: 'Organization',
    meta: {
      requiresAuth: false,
    },
    component: function () {
      return import('../views/OrganizationView.vue')
    }
  },
  {
    path: '/catalog',
    name: 'Catalog',
    meta: {
      requiresAuth: false,
    },
    component: function () {
      return import('../views/CatalogView.vue')
    }
  },
  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if ($cookies.get('token')) {
      next();
    } else {
      next({ name: "home" });
    }
  } else {
    next();
  }
});

export default router
