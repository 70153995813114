import { createStore } from "vuex";
import axios from "axios";
import router from "../router";

export default createStore({
  state: {
    cdn: process.env.APP_CDN,
    searchCategory: [],
    location: [],
    isAuth: false,
    unAuth: false,
    userInfo: null,
    product: null,
    category: null,
    opened: false,
  },
  getters: {
    GET_SEARCH_CATEGORY: (state) => state.searchCategory,
    GET_LOCATION: (state) => state.location,
    GET_UN_AUTH: (state) => state.unAuth,
    GET_IS_AUTH: (state) => state.isAuth,
    GET_USER: (state) => state.userInfo,
    GET_PRODUCT: (state) => state.product,
    GET_CATEGORY: (state) => state.category,
    GET_OPENED: (state) => state.opened,
  },
  mutations: {
    SET_SEARCH_CATEGORY(state, searchCategory) {
      state.searchCategory = searchCategory;
    },
    SET_LOCATION(state, location) {
      state.location = location;
    },
    SET_AUTH(state, isAuth) {
      state.isAuth = isAuth;
    },
    SET_UN_AUTH(state, unAuth) {
      state.unAuth = unAuth;
    },
    SET_USER(state, userInfo) {
      state.userInfo = userInfo;
    },
    SET_PRODUCT(state, product) {
      state.product = product;
    },
    SET_CATEGORY(state, category) {
      state.category = category;
    },
    SET_MODAL(state) {
      state.opened = !state.opened;
    },
  },
  actions: {
    async FETCH_SEARCH_CATEGORY({ commit }) {
      try {
        const data = await axios.get("categories");
        if (data.status === 200) {
          commit("SET_SEARCH_CATEGORY", data.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async FETCH_LOCATION({ commit }) {
      try {
        const data = await axios.get("locations");
        if (data.status === 200) {
          commit("SET_LOCATION", data.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async FETCH_USER({ commit }) {
      try {
        const data = await axios.get("user-profile", {
          headers: {
            Authorization: `Bearer ${$cookies.get("token")}`,
          },
        });
        commit("SET_USER", data.data.data);
        commit("SET_UN_AUTH", false);
        commit("SET_AUTH", true);
      } catch (error) {
        console.log(error);
        $cookies.remove("token");
        commit("SET_UN_AUTH", true);
        commit("SET_AUTH", false);
      }
    },
    CHECK_AUTH({ commit }) {
      if ($cookies.get("token")) {
        // commit("SET_AUTH", true);
        // commit("FETCH_USER")
        this.dispatch("FETCH_USER");
      }
    },
    LOGOUT({ commit }) {
      $cookies.remove("token");
      commit("SET_AUTH", false);
      router.push({ name: "home" });
    },
    async FETCH_PRODUCT({ commit }, { slug }) {
      try {
        const data = await axios.get("product", {
          params: {
            slug: slug,
          },
        });
        if (data.status === 200) {
          commit("SET_PRODUCT", data.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async FETCH_CATEGORY(
      { commit },
      {
        slug,
        location_id,
        search,
        page,
        maxprice,
        minprice,
        exchange,
        photo,
        delivery,
      }
    ) {
      try {
        const data = await axios.get("categories-by-slug", {
          params: {
            slug: slug,
            location_id: location_id,
            search: search,
            page: page,
            max_price: maxprice || 9999999999999,
            min_price: minprice || 0,
            is_exchange: exchange == 'true' ? exchange : '',
            is_photo: photo == 'true' ? photo : "",
            is_delivery: delivery == 'true' ? delivery : "",
          },
        });
        if (data.status === 200) {
          commit("SET_CATEGORY", data.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
});
