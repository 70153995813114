<template>
  <div v-if="footerData" class="footer">
    <div class="footer__content">
      <div class="footer__items">
        <div class="footer__items-top">
          <div class="container">
            <div class="footer__items-top-logo">
              <img src="@/assets/images/svg/MainLogo.svg" alt="" />
            </div>
            <div class="footer__items-top-route">
              <ul>
                <li v-for="(item, idx) in navLink" :key="idx">
                  <a @click="toPage(item)" class="f-16">{{
                    item.name
                  }}</a>
                </li>
              </ul>
            </div>
            <div class="footer__items-top-social">
              <a :href="item.link" target="_blank" v-for="(item, idx) in footerData?.socials" :key="'s' + idx"
                ><img :src="item.image" alt=""
              /></a>
            </div>
          </div>
        </div>
        <div class="footer__items-bottom">
          <div class="container">
            <div class="footer__items-bottom-left">
              <a class="f-16">© 2023 "MalMarket"</a>
              <p class="f-16">{{ $t("footer.allright") }}</p>
            </div>
          
            <!-- <div class="footer__items-bottom-right">
            <a class="f-16" v-for="(item , idx) in docs2" :key="'d2'+ idx">{{ item.title }}</a>
            </div> -->
          </div>
        </div>
        <div class="footer__items-links">
            <div class="container">
              <a target="_blank" v-for="(item,idx) in footerData?.agreements" :href="item.link" :key="'d' + idx"
                ><span><svg
                  width="12"
                  height="16"
                  viewBox="0 0 12 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 0C0.89543 0 0 0.89543 0 2V14C0 15.1046 0.89543 16 2 16H10C11.1046 16 12 15.1046 12 14V5.41421C12 4.88378 11.7893 4.37507 11.4142 4L8 0.58579C7.6249 0.21071 7.1162 0 6.5858 0H2ZM7 6C7 5.44772 6.5523 5 6 5C5.44772 5 5 5.44772 5 6V9.5858L3.70711 8.2929C3.31658 7.90237 2.68342 7.90237 2.29289 8.2929C1.90237 8.6834 1.90237 9.3166 2.29289 9.7071L5.29289 12.7071C5.68342 13.0976 6.3166 13.0976 6.7071 12.7071L9.7071 9.7071C10.0976 9.3166 10.0976 8.6834 9.7071 8.2929C9.3166 7.90237 8.6834 7.90237 8.2929 8.2929L7 9.5858V6Z"
                    fill="#454545"
                  />
                </svg></span><p style="color: #454545" class="f-16">{{item.title}}</p>
              </a>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() { 
    return {
    navLink: [
      {
        name: this.$t('links.ads'),
        route: "Catalog",
        slug: ""
      },
      {
        name: this.$t('links.news'),
        route: "News",
        slug: ""
      },
      {
        name: this.$t('links.prodvizh'),
        route: "Promotion",
        slug: ""
      },
      {
        name: this.$t('links.organization'),
        route: "Organization",
         slug: ""
      },
      {
        name: this.$t('links.uslugi'),
        route: "CategoryBySlug",
        slug: "uslugi"
      },
      {
        name: this.$t('links.forums'),
        route: "Forums",
      },
    ],
    footerData: null,
    social: [
      {
        icon: require("@/assets/images/svg/whatsapp.svg"),
        href: "",
        blanc: "",
      },
      {
        icon: require("@/assets/images/svg/telegram.svg"),
        href: "",
        blanc: "",
      },
      {
        icon: require("@/assets/images/svg/vk.svg"),
        href: "",
        blanc: "",
      },
      {
        icon: require("@/assets/images/svg/instagram.svg"),
        href: "",
        blanc: "",
      },
      {
        icon: require("@/assets/images/svg/youtube.svg"),
        href: "",
        blanc: "",
      },
    ],
    docs: [],
  }},
  created(){
    this.FETCH_FOOTER();
  },
  methods: {
    toPage(value){
      if(value.route == 'CategoryBySlug'){
        console.log('111');
        this.$router.push({ name: value.route, params: { Categoryslug : value.slug }})
      } else {
        console.log('222');
        this.$router.push({ name: value.route})
      }
    },
    FETCH_FOOTER() {
      axios
        .get("contacts", {
          headers: {
            Authorization: `Bearer ${this.$cookies.get("token")}`,
          },
        })
        .then((response) => {
          this.footerData = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  }
};
</script>

<style></style>
