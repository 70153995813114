<template>
    <div v-if="homeData" class="newest">
      <div class="newest__items">
        <div class="newest__items-top">
          <div class="newest__items-top-left">
            <span class="f-22"> {{ $t("homepage.fastest") }}</span>
            
          </div>
          <div class="newest__items-top-right">
              <div class="newest__items-top__navigation">
              <div class="swiper-button-next selectornx4"></div>
              <div class="swiper-button-prev selectorpr4"></div>
            </div>
          </div>
        </div>    
        <div class="newest__items-bottom">
          <!-- :grid="{ rows: 2 }" -->
          <swiper
           
            :slidesPerView="5"
            :spaceBetween="5"
            :breakpoints="breakpoints"
            :modules="modules"
            :navigation="navigation"
            class="newest__items-bottom-swiper"
          >
            <swiper-slide
              class="newest__items-bottom-swiper-swiper-slide"
              v-for="item in homeData.urgentPosts"
              :key="item.id"
            >
              <Product @update="update" :product="item" />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Product from "../Product";
  import { Swiper, SwiperSlide } from "swiper/vue";
  import SwiperCore, { Grid, Navigation } from "swiper";
  import "swiper/css/grid";
  SwiperCore.use([Navigation, Grid]);
  export default {
    props:['homeData'],
    components: { Product, Swiper, SwiperSlide },
    data: () => ({
      navigation: {
      nextEl: ".selectornx4",
      prevEl: ".selectorpr4",
    },
    breakpoints: {
      // when window width is >= 320px
      0: {
        slidesPerView: 1.1,
        spaceBetween: 10,
      },
      // when window width is >= 480px
      655: {
        slidesPerView: 1.2,
        spaceBetween: 10,
      },
      // when window width is >= 640px
      1000: {
        slidesPerView: 1.5,
        spaceBetween: 33,
      },
      1300: {
        slidesPerView: 5,
        spaceBetween: 13,
      },
    },
    }),
    methods: {
      update(){
     
     this.$emit('update')
   }
    }
  };
  </script>
  
  <style lang="scss">
  .swiper-wrapper {
    flex-direction: unset !important;
  }
  .swiper-slide.newest__items-bottom-swiper-swiper-slide.swiper-slide-next {
    margin-top: 0 !important;
  }
  
  .swiper-slide.newest__items-bottom-swiper-swiper-slide {
    margin-top: 0 !important;
    // margin-bottom: 5px!important;
  }
  </style>
  