<template>
  <div class="pLayout">
    <div class="pLayout__content container">
      <div class="pLayout__items">
        <div class="pLayout__items-navs m-none">
          <div class="pLayout__items-navs-tab">
            
            <ul>
              <li v-for="(item, idx) in menu" :key="idx">
                <router-link :class="$route.name === item.link ? 'activeLinks' : ''" class="f-16"
                  :to="{ name: item.link }">
                  {{ item.text }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="pLayout__items-mobileNav d-none">
            <SelectComponent
            :selected="selected"
            :options="menu"
            @select="selectOption"
            />
          </div>

        <div class="pLayout__items-page">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SelectComponent from "@/components/SelectComponentv2";
export default {
  name: "PersonalLayout",
  components: {
    SelectComponent
  },
  data: () => ({
    menu: [
      {
        id: 0,
        text: "Мой профиль",
        link: "MyProfile",
      },
      {
        id: 1,
        text: "Мои объявления",
        link: "MyAds",
      },
      {
        id: 2,
        text: "Сообщения",
        link: "MyMessages",
      },
      {
        id: 3,
        text: "Баланс",
        link: "MyBalance",
      },
      {
        id: 4,
        text: "Избранное",
        link: "MyFavourites",
      },
      // {
      //   id: 5,
      //   text: "Настройки",
      //   link: "home",
      // },
      {
        id: 5,
        text: "Моя организация",
        link: "MyOrganization",
      },
    ],
    selected: "",

  }),
  created() {
    this.FETCH_USER();
    this.selected = this.menu.find(item => this.$route.name === item.link )
  },
  computed: {
    ...mapGetters({ userInfo: 'GET_USER' })
  },
  methods: {
    ...mapActions(['FETCH_USER']),
    selectOption(option) {
     this.$router.push({name: option.link});
     this.selected = option;
     this.$nextTick(this.$forceUpdate);

    },
  }
};
</script>

<style scoped lang="scss">
.pLayout {
  min-height: 100vh;
  margin-top: 24px;

  &__items {
    display: grid;
    grid-template-columns: 25% calc(75% - 15px);
    grid-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
 
    @media (max-width: 995px) {
      display: flex;
      flex-direction: column;
      gap: 64px;
    }
    @media (max-width: 576px) {
      gap: 20px;
    }
    &-navs,
    &-page {
      background: #fafffe;
      border: 1px solid #e3e3e3;
      border-radius: 5px;
    }

    &-navs {
      padding: 15px 12px;

      &-tab {
        ul {
          display: grid;
          gap: 15px;

          li {
            .activeLinks {
              color: #454545 !important;
            }

            a {
              font-weight: 500;
              line-height: 19px;
              color: #1c682c;
            }
          }
        }
      }
    }

    &-page {
      padding: 24px 25px;
    }
  }

}
</style>
